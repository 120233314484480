function arrayChunker(baseArray: any[], chunkSize: number) {
  const splittedArray = [];
  for (let i = 0; i < baseArray.length; i += chunkSize) {
    const chunk = baseArray.slice(i, i + chunkSize);
    splittedArray.push(chunk);
  }
  return splittedArray;
}

export { arrayChunker };
