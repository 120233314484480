import { auth, db } from './config';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import { query, collection, where, getDocs } from 'firebase/firestore';

import {
  UserModel,
  TokenModel,
  UsersWithSameTokensModel,
  TokenDocumentModel,
} from './firestoreOrm/models';

import { arrayChunker } from '$lib/ts/arrayChunker';
import { meta_config } from '../../metaweb.config.js';

const DATA_VERSION = meta_config.DATABASE_VERSION;

const dataBase =
  import.meta.env.VITE_FIREBASE_WORKING_ENV === 'PROD' // PROD or DEV
    ? 'metaweb-db'
    : 'metaweb-db-dev';

class DataStore {
  user: any;
  token: any;
  usersWithSameTokens: any;
  tokenDocument: any;

  async getCurrentUser(): Promise<any> {
    return new Promise((res, rej) => {
      let currentUser = auth.currentUser;
      let counter: number = 0;
      if (currentUser) {
        res({ id: currentUser.uid });
      } else {
        const userPoller = setInterval(async () => {
          counter++;
          currentUser = auth.currentUser;
          if (currentUser) {
            clearInterval(userPoller);

            this.user = new UserModel();
            this.user.userId = currentUser.uid;
            const userData = await this.user.getDataByDocumentData('==');

            res({
              id: currentUser.uid,
              email: currentUser.email,
              userName: userData[0].userName,
            });
          } else if (!currentUser && counter >= 30) {
            clearInterval(userPoller);
            rej('no user');
          }
        }, 100);
      }
    });
  }

  async searchUsers(userName: string): Promise<any[]> {
    if (userName) {
      const userQuery = query(
        collection(db, dataBase),
        where('userName', '>=', userName),
        where('userName', '<=', userName + '\uf8ff')
      );
      const querySnapshot = await getDocs(userQuery);
      const users = querySnapshot.docs.map((user) => user.data());

      return users;
    }
    return [];
  }

  async claimAccount(accountData: ClaimAccountData): Promise<ClaimAccountTypes> {
    let emailUpdated: boolean = false;
    let passwordUpdated: boolean = false;

    let claimed: boolean = false;
    let message: string = `Error claiming account with email ${accountData.email}`;
    let errors: string[] = [];
    const currentUser = auth.currentUser;

    if (currentUser) {
      await updateEmail(currentUser, accountData.email)
        .then(() => {
          emailUpdated = true;
        })
        .catch((error: any) => {
          emailUpdated = true;
          errors.push(error.message);
        });

      await updatePassword(currentUser, accountData.password)
        .then(() => {
          passwordUpdated = true;
        })
        .catch((error: any) => {
          passwordUpdated = false;
          errors.push(error.message);
        });
    } else {
      message = 'Anonymous user not found';
    }

    if (emailUpdated && passwordUpdated) {
      this.user = new UserModel();
      this.user.userEmail = accountData.email;
      this.user.userName = accountData.userName;

      const updateProfile = await this.user.updateWithDocumentId(currentUser?.uid);
      if (updateProfile) {
        claimed = true;
        message = `Käyttäjä: ${currentUser?.uid} lunastettu sähköpostilla ${accountData.email}`;
      } else {
        message = 'Error updating profile';
      }
    }

    return {
      claimed: claimed,
      message: message,
      errors: errors,
    };
  }

  async createAccount(
    email: string,
    userName: string,
    password: string
  ): Promise<LogiInReturnTypes> {
    let loggedIn = false;
    let errorMessage: string = '';
    let currentUser: any = auth.currentUser;
    this.user = new UserModel();

    if (currentUser) {
      signOut(auth)
        .then(() => {
          console.log(`Signed out user with id: ${currentUser.uid}`);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        const user = result.user;
        let loginStatus: string = 'SUCCESS';

        this.user.userEmail = user.email;
        this.user.userName = userName;
        this.user.userId = user.uid;

        const checkExistingUser = await this.user.getDataByDocumentId(user.uid);
        if (!checkExistingUser.data) {
          const createUser = await this.user.createWithDocumentId(user.uid);
          loginStatus = createUser.status;
        }
        loginStatus === 'SUCCESS'
          ? ((loggedIn = true), (currentUser = user.uid))
          : ((loggedIn = false), (currentUser = ''));
      })
      .catch((err) => {
        errorMessage = err.message;
        loggedIn = false;
        currentUser = '';
      });

    return {
      loggedIn: loggedIn,
      userId: currentUser,
      errorMessage: errorMessage,
    };
  }

  async signIn(
    provider: string,
    email?: string,
    password?: string,
    userName?: string
  ): Promise<LogiInReturnTypes> {
    const providers: any = {
      google: new GoogleAuthProvider(),
    };

    let currentUser: any = auth.currentUser;
    let loggedIn: boolean = false;
    let errorMessage: string = '';
    this.user = new UserModel();

    if (currentUser) {
      signOut(auth)
        .then(() => {
          console.log(`Signed out user with id: ${currentUser.uid}`);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (provider !== 'email') {
      await signInWithPopup(auth, providers[provider])
        .then(async (result) => {
          const user = result.user;
          const userName = user.email?.split('@')[0];
          let loginStatus: string = 'SUCCESS';

          this.user.userEmail = user.email;
          this.user.userName = userName;
          this.user.userId = user.uid;

          const checkExistingUser = await this.user.getDataByDocumentId(user.uid);
          if (!checkExistingUser.data) {
            const createUser = await this.user.createWithDocumentId(user.uid);
            loginStatus = createUser.status;
          }
          loginStatus === 'SUCCESS'
            ? ((loggedIn = true), (currentUser = user.uid))
            : ((loggedIn = false), (currentUser = ''));
        })
        .catch((err) => {
          errorMessage = err.message;
          loggedIn = false;
          currentUser = '';
        });

      return {
        loggedIn: loggedIn,
        userId: currentUser,
        errorMessage: errorMessage,
      };
    }

    await signInWithEmailAndPassword(auth, email as string, password as string)
      .then(async (result) => {
        const user = result.user;
        let loginStatus: string = 'SUCCESS';

        this.user.userEmail = user.email;
        this.user.userName = userName;
        this.user.userId = user.uid;

        const checkExistingUser = await this.user.getDataByDocumentId(user.uid);
        if (!checkExistingUser.data) {
          const createUser = await this.user.createWithDocumentId(user.uid);
          loginStatus = createUser.status;
        }
        loginStatus === 'SUCCESS'
          ? ((loggedIn = true), (currentUser = user.uid))
          : ((loggedIn = false), (currentUser = ''));
      })
      .catch((err) => {
        switch (err.message) {
          case 'Firebase: Error (auth/wrong-password).':
            errorMessage = 'Invalid password';
            break;
          case 'Firebase: Error (auth/user-not-found).':
            errorMessage = 'User not found';
            break;
          default:
            errorMessage = err.message;
            break;
        }

        loggedIn = false;
        currentUser = '';
      });

    return {
      loggedIn: loggedIn,
      userId: currentUser,
      errorMessage: errorMessage,
    };
  }

  async signOut(): Promise<boolean> {
    let signedOut: boolean = false;

    await signOut(auth)
      .then(() => {
        signedOut = true;
      })
      .catch((err) => {
        console.error(err);
        signedOut = false;
      });

    return signedOut;
  }

  async createToken(tokenData: TokenTypes, userId: string): Promise<any> {
    this.token = new TokenModel();

    this.token.trustLevel = tokenData.trustLevel;
    this.token.placeName = tokenData.placeName;
    this.token.placeId = tokenData.placeId;
    this.token.tokenDescription = tokenData.tokenDescription;
    this.token.imageData = tokenData.imageData;
    this.token.version = DATA_VERSION;
    this.token.userId = userId;
    this.token.timeCreated = new Date()

    const createToken = await this.token.mutate({ userId: userId }, 'create');
    if (createToken.status !== 'ERROR') {
      return {
        message: `${tokenData.placeName} tallennettu!`,
        status: 'SUCCESS',
        data: createToken.data,
      };
    }
    return {
      message: `Virhe tallentaessa tokenia "${tokenData.placeName}"`,
      status: 'ERROR',
      data: null,
    };
  }

  async deleteUserToken(tokenId: number, userId: string): Promise<any> {
    this.token = new TokenModel();

    this.token.placeId = tokenId;
    this.token.userId = userId;

    const deleteToken = await this.token.mutate({ userId: userId }, 'delete');
    if (deleteToken.status !== 'ERROR') {
      return {
        message: `${tokenId} poistettu!`,
        status: 'SUCCESS',
        data: deleteToken.data,
      };
    }
    return {
      message: `Virhe poistaessa tokenia "${tokenId}"`,
      status: 'ERROR',
      data: null,
    };
  }

  async getUserTokens(userId: string): Promise<any[]> {
    this.token = new TokenModel();
    this.token.userId = userId;

    const userTokens = await this.token.getDataByDocumentData('==');
    return userTokens;
  }

  async userHasToken(userId: string, tokenId: number): Promise<boolean> {
    this.token = new TokenModel();
    this.token.placeId = tokenId;
    this.token.userId = userId;

    const tokenData = await this.token.getDataByDocumentData('==');
    return tokenData[0] ? true : false;
  }

  async getOneToken(placeId: number, userId: string): Promise<any[]> {
    this.token = new TokenModel();
    this.token.placeId = placeId;
    this.token.userId = userId;

    const tokenData = await this.token.getDataByDocumentData('==');
    return tokenData[0];
  }

  async getUsersWithSameTokens(placeId: number, userId: string): Promise<any[]> {
    let userIds: string[] = [];
    let usersWithSameTokens: any[] = [];
    let uniqueUsers: any[] = [];

    this.token = new TokenModel();
    this.token.placeId = placeId;

    const tokenData = await this.token.getDataByDocumentData('==');
    tokenData.forEach((token: any) => {
      if (token.userId !== userId && !userIds.includes(token.userId)) {
        userIds.push(token.userId);
      }
    });

    if (userIds.length > 0) {
      const userIdsChunked = arrayChunker(userIds, 30);

      for (const userIds in userIdsChunked) {
        this.usersWithSameTokens = new UsersWithSameTokensModel();
        this.usersWithSameTokens.userId = userIdsChunked[userIds];

        const users = await this.usersWithSameTokens.getDataByDocumentData('in');
        usersWithSameTokens.push(users);
      }

      const flattenArray = usersWithSameTokens.flat();
      flattenArray.forEach((object) => {
        if (
          uniqueUsers.findIndex((item) => {
            return item.userId === object.userId;
          })
        ) {
          uniqueUsers.push(object);
        }
      });

      return uniqueUsers;
    }
    return [];
  }

  async getTokenDocument(documentId: string) {
    this.tokenDocument = new TokenDocumentModel();
    const tokenData = await this.tokenDocument.getDataByDocumentId(String(documentId));

    if (tokenData.data) {
      return {
        status: 'SUCCESS',
        placeName: tokenData.data.placeName,
        tokenDescription: tokenData.data.tokenDescription,
        imageData: tokenData.data.imageData,
      };
    }
    return {
      status: 'ERROR',
      placeName: null,
      tokenDescription: null,
      imageData: null,
    };
  }

  async createTokenDocument(tokenDocumentData: TokenDocumentTypes) {
    this.tokenDocument = new TokenDocumentModel();
    const tokenData = await this.tokenDocument.getDataByDocumentId(
      String(tokenDocumentData.placeId)
    );

    if (tokenData.data) {
      return {
        status: 'SUCCESS',
        placeName: tokenData.data.placeName,
        tokenDescription: tokenData.data.tokenDescription,
        imageData: tokenData.data.imageData,
      };
    }
    if (tokenDocumentData.placeName == null) {
      throw 'placeName must not be null when creating a document.';
    }

    this.tokenDocument.placeName = tokenDocumentData.placeName;
    this.tokenDocument.tokenDescription = tokenDocumentData.tokenDescription;
    this.tokenDocument.imageData = tokenDocumentData.imageData;
    this.tokenDocument.version = DATA_VERSION;

    const createToken = await this.tokenDocument.createWithDocumentId(
      String(tokenDocumentData.placeId)
    );
    return {
      status: 'SUCCESS',
      placeName: createToken.data.placeName,
      tokenDescription: createToken.data.tokenDescription,
      imageData: createToken.data.imageData,
    };
  }
}

export { DataStore };
