import { Collection, SubCollection } from './decorators';

const collection =
  import.meta.env.VITE_FIREBASE_WORKING_ENV === 'PROD' // PROD or DEV
    ? 'metaweb-db'
    : 'metaweb-db-dev';

@Collection(collection)
class UserModel {
  userName!: string;
  userEmail!: string;
  userId!: string;

  [newMethods: string]: any;
}

@Collection(collection)
class UsersWithSameTokensModel {
  userId!: string[];

  [newMethods: string]: any;
}

@Collection('places')
class TokenDocumentModel {
  placeName!: string | null;
  tokenDescription!: string | null;
  imageData!: string | null;
  version!: number;

  [newMethods: string]: any;
}

@SubCollection(collection, 'NFT')
class TokenModel {
  trustLevel!: number;
  placeName!: string;
  version!: number;
  userId!: string;
  placeId!: number;
  tokenDescription!: string | null;
  imageData!: string | null;
  timeCreated!: Date

  [newMethods: string]: any;
}

export { UserModel, TokenModel, UsersWithSameTokensModel, TokenDocumentModel };
