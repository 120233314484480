import { db } from '../config';
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteField,
  deleteDoc,
  getDoc,
  collectionGroup,
  setDoc,
  type WhereFilterOp,
} from 'firebase/firestore';
import type {
  CollectionReference,
  DocumentData,
  QueryFieldFilterConstraint,
  Query,
  DocumentReference,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

function Collection(collectionName: string) {
  return function collectionDecorator<T extends { new (...args: any[]): Allowall }>(model: T) {
    return class extends model {
      collection: CollectionReference<DocumentData>;
      queries: QueryFieldFilterConstraint[];

      constructor(...args: any[]) {
        super(...args);
        this.collection = collection(db, collectionName);
        this.queries = [];
      }

      async create(checkExistingData: boolean): Promise<any> {
        const { collection, queries, ...modelData } = this;

        if (checkExistingData) {
          Object.keys(modelData).forEach((key) => {
            if (modelData[key]) {
              this.queries.push(where(key, '==', modelData[key]));
            }
          });
          const checkQuery = query(this.collection, ...this.queries);
          const querySnapshot = await getDocs(checkQuery);

          if (querySnapshot.empty) {
            const createDocument = await Promise.allSettled([addDoc(this.collection, modelData)]);

            if (createDocument[0].status === 'fulfilled') {
              return {
                message: 'Data Created',
                status: 'SUCCESS',
                data: modelData,
              };
            }
            return {
              message: 'Error creating data',
              status: 'ERROR',
              data: null,
            };
          }
          let data: any[] = [];
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });

          return {
            message: 'Data already exists',
            status: 'SUCCESS',
            data: data[0],
          };
        }

        const createDocument = await Promise.allSettled([addDoc(this.collection, modelData)]);

        if (createDocument[0].status === 'fulfilled') {
          return {
            message: 'Data created',
            status: 'SUCCESS',
            data: modelData,
          };
        }
        return {
          message: 'Error creating data',
          status: 'ERROR',
          data: null,
        };
      }

      async updateWithDocumentId(docId: string) {
        const { collection, queries, ...modelData } = this;
        if (docId) {
          let newModelData: any = {};

          Object.keys(modelData).forEach((key) => {
            if (modelData[key]) {
              newModelData[key] = modelData[key];
            }
          });

          const documentRef = doc(db, collectionName, docId);
          const updateDocument = await Promise.allSettled([updateDoc(documentRef, newModelData)]);

          return updateDocument[0].status;
        }

        return 'rejected';
      }

      async createWithDocumentId(docId: string) {
        const { collection, queries, ...modelData } = this;
        if (docId) {
          const documentRef = doc(db, collectionName, docId);
          const createData = await Promise.allSettled([setDoc(documentRef, modelData)]);

          if (createData[0].status === 'fulfilled') {
            return {
              docId: docId,
              data: modelData,
              status: 'SUCCESS',
            };
          }
          return {
            docId: null,
            data: null,
            status: 'ERROR',
          };
        }
        return {
          docId: null,
          data: null,
          status: 'ERROR',
        };
      }

      async getDataByDocumentData(operator: WhereFilterOp) {
        const data: any = [];
        const { collection, queries, ...modelData } = this;

        Object.keys(modelData).forEach((key) => {
          if (modelData[key]) {
            this.queries.push(where(key, operator, modelData[key]));
          }
        });

        const collectionQuery = query(this.collection, ...this.queries);
        const querySnapshot = await getDocs(collectionQuery);
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });

        return data;
      }

      async getDataByDocumentId(docId: string) {
        if (docId) {
          const documentRef = doc(db, collectionName, docId);
          const documentData = await getDoc(documentRef);

          if (documentData.exists()) {
            return {
              message: `Data found for id: ${documentData.id}`,
              status: 'SUCCESS',
              data: documentData.data(),
            };
          }
          return {
            message: `No data found for id: ${docId}`,
            status: 'ERROR',
            data: null,
          };
        }
        return {
          message: `No document id provided`,
          status: 'ERROR',
          data: null,
        };
      }
    };
  };
}

function SubCollection(collectionName: string, subCollectionName: string) {
  return function subCollectionDecorator<T extends { new (...args: any[]): Allowall }>(model: T) {
    return class extends model {
      collection: CollectionReference<DocumentData>;
      collectionGroup: Query<DocumentData>;
      collectionQueries: QueryFieldFilterConstraint[];
      collectionGroupQueries: QueryFieldFilterConstraint[];

      constructor(...args: any[]) {
        super(...args);
        this.collection = collection(db, collectionName);
        this.collectionGroup = collectionGroup(db, subCollectionName);
        this.collectionQueries = [];
        this.collectionGroupQueries = [];
      }

      async mutate(colWhereData: any, mutation: string): Promise<any> {
        const {
          collection,
          collectionQueries,
          collectionGroup,
          collectionGroupQueries,
          ...modelData
        } = this;

        if (mutation === 'create') {
          Object.keys(colWhereData).forEach((key) => {
            this.collectionQueries.push(where(key, '==', colWhereData[key]));
          });

          const docQuery = query(this.collection, ...collectionQueries);
          const querySnapshot = await getDocs(docQuery);
          if (querySnapshot.empty) {
            return {
              message: 'No base collection found',
              status: 'ERROR',
            };
          }

          const docIds = querySnapshot.docs.map((doc) => doc.id);
          const subCollectionId = uuidv4();
          const docRef = doc(db, collectionName, docIds[0], subCollectionName, subCollectionId);

          const createSubCollection = await Promise.allSettled([setDoc(docRef, modelData)]);

          if (createSubCollection[0].status === 'fulfilled') {
            return {
              message: 'Subcollection created succesfully',
              status: 'SUCCESS',
              data: modelData,
            };
          }
          return {
            message: 'Error created subcollection',
            status: 'ERROR',
            data: null,
          };
        }

        Object.keys(colWhereData).forEach((key) => {
          this.collectionQueries.push(where(key, '==', colWhereData[key]));
        });
        Object.keys(modelData).forEach((key) => {
          if (modelData[key]) {
            this.collectionGroupQueries.push(where(key, '==', modelData[key]));
          }
        });

        const docQuery = query(this.collection, ...this.collectionQueries);
        const docQuerySnapshot = await getDocs(docQuery);
        const docIds = docQuerySnapshot.docs.map((doc) => doc.id);

        const colGroupQuery = query(this.collectionGroup, ...this.collectionGroupQueries);
        const colGroupQuerySnapshot = await getDocs(colGroupQuery);
        const colGroupIds = colGroupQuerySnapshot.docs.map((colGroup) => colGroup.id);
        const colGroupData = colGroupQuerySnapshot.docs.map((colGroup) => colGroup.data());

        const docRef = doc(db, collectionName, docIds[0], subCollectionName, colGroupIds[0]);

        const deleteSubCollection = await Promise.allSettled([deleteDoc(docRef)]);
        if (deleteSubCollection[0].status === 'fulfilled') {
          return {
            message: 'Subcollection deleted succesfully',
            status: 'SUCCESS',
            data: colGroupData[0],
          };
        }
        return {
          message: 'Error deleting subcollection',
          status: 'ERROR',
          data: null,
        };
      }

      async getDataByDocumentData(operator: WhereFilterOp) {
        const data: any = [];
        const {
          collection,
          collectionQueries,
          collectionGroup,
          collectionGroupQueries,
          ...modelData
        } = this;

        Object.keys(modelData).forEach((key) => {
          if (modelData[key]) {
            this.collectionGroupQueries.push(where(key, operator, modelData[key]));
          }
        });

        const subCollectionQuery = query(this.collectionGroup, ...this.collectionGroupQueries);
        const querySnapshot = await getDocs(subCollectionQuery);
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });

        return data;
      }
    };
  };
}

export { Collection, SubCollection };
